import React, { useState, useContext, useEffect } from "react";
import Select from "react-select";
import Header from "../../general/navigation/Header";
import { CountryContext } from '../../context/CountryContext';
import { CompanyContext } from '../../context/CompanyContext';
import JobMappingRow from "./JobMappingRow";

const JobMapping = (props) => {

  // eslint-disable-next-line no-unused-vars
  const [countryState, countryDispatch] = useContext(CountryContext);
  // eslint-disable-next-line no-unused-vars
  const [companyState, companyDispatch] = useContext(CompanyContext);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [companyId] = useState(localStorage.getItem("companyId"));



  useEffect(() => {
    if (companyState.selectedCompany === null || countryState.countries.length <=0) {
        window.location.replace('/#/');
    }
    setSelectedCompany(companyState.selectedCompany);
    setCountries(countryState.countries);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  /**
   * Sets the state of the current selected country to be the name of the country which is selected from the dropdown.
   */
  const handleCountrySelect = (event) => {
    const country = event.label;
    setCountry(country);
  };

  const setCountry = (country) => {
    let matchingCountry = countries.filter(
      (selectedCountry) => selectedCountry.name === country
    )[0];
    setSelectedCountry(matchingCountry);
  };

  /**
   * Returns an array of options for the list of countries to be shown in the dropdown.
   */
  const returnCountries = () => {
    const options = [];
    for (const country of countries) {
      options.push({
        value: country,
        label: country.name,
      });
    }
    return options;
  };

  return (
    <React.Fragment>
      {selectedCompany && (
        <React.Fragment>
          <Header
            showClientNavigation={true}
            showImmigrationNavigation={false}
            displayName={selectedCompany.name}
            link={"/select-company"}
          />
          <h3 className="container text-secondary text-bold pb-4">
            Map Job Positions to Job Codes
          </h3>
          <div className="container d-flex justify-content-center">
            <div className="form-group map-select col-6">
              <label htmlFor="country-select" className="text-secondary">
                SELECT COUNTRY
              </label>
              <Select
                onChange={(event) => handleCountrySelect(event)}
                options={returnCountries()}
              />
            </div>
          </div>
          <div className="container d-flex justify-content-center">
        <span className="text-secondary" style={{textAlign:"center", fontStyle:'italic'}}>To add multiple values paste the job positions followed by a semicolon (e.g. Account Executive;)</span>
          </div>

          <div className="container d-flex justify-content-center mt-4">
            {selectedCountry && (
              <table className="table table-responsive-sm mx-5">
                <thead>
                  <tr>
                    <th className="text-secondary w-50" scope="col">
                      Job Codes
                    </th>
                    <th className="text-secondary w-50" scope="col">
                      Job Positions
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedCountry.jobCodes.map((code, index) => {
                    return (
                      <JobMappingRow
                        key={index + code.name}
                        code={code}
                        index={index}
                        companyId={companyId}
                        selectedCountry={selectedCountry}
                        selectedCompany={selectedCompany}
                        fetchCountries={CountryContext.countries}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default JobMapping;
