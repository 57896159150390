import React, { Fragment, useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import { api } from '../../api';
import { CompanyContext } from '../../context/CompanyContext';
import { CountryContext } from '../../context/CountryContext';
import { fetchCompany } from '../../services/CompanyService';
import { fetchCountries } from '../../services/CountryService';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading';
import { useParams } from 'react-router';

const JobPositionToFieldOfStudyMapping = () => {
  const { id } = useParams();
  const [companyState, companyDispatch] = useContext(CompanyContext);
  const [countryState, countryDispatch] = useContext(CountryContext);
  const [fieldOfStudyJobPositions, setFieldOfStudyJobPositions] = useState([]);
  const [fieldOfStudyJobPositionIds, setFieldOfStudyJobPositionIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [jobPositionsDropdown, setJobPositionsDropdown] = useState([]);
  const [countryDropdown, setCountryDropdown] = useState([]);
  const [fieldOfStudyDropdown, setFieldOfStudyDropdown] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedFieldOfStudy, setSelectedFieldOfStudy] = useState(null);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch the countries and company data if they can't be found in the context
   */
  const fetchData = async () => {
    if (!companyState.selectedCompany) {
      const companyResponse = await fetchCompany(id);

      companyDispatch({
        type: 'SELECT_COMPANY',
        selectedCompany: companyResponse,
      });
    }

    if (countryState.countries.length <= 0) {
      const countriesResponse = await fetchCountries();

      countryDispatch({
        type: 'FETCH_COUNTRIES',
        countries: countriesResponse,
      });
      buildCountryDropdown(countriesResponse);
    } else {
      buildCountryDropdown();
    }
    const fieldsOfStudyResponse = await api.get(`/fields-of-study`);
    buildFieldsOfStudyDropdown(fieldsOfStudyResponse.data);

    const jobPositionsResponse = await api.get(`/companies/${id}/job-positions`)
    buildJobPositionsDropdown(jobPositionsResponse.data);

    setIsLoading(false);
  };

  /**
   * This builds the job positions dropdown items.
   * @param {array} jobPositions An array of objects which hold job position information
   */
  const buildJobPositionsDropdown = (jobPositions) => {
    let tempJobPositionsDropdown = [];

    for (const jobPosition of jobPositions) {
      tempJobPositionsDropdown.push({
        label: jobPosition.jobFamily,
        value: jobPosition.id,
      });
    }

    setJobPositionsDropdown(tempJobPositionsDropdown);
  };

  /**
   * Returns an array of options containing the countries to be shown in the dropdown.
   */
  const buildCountryDropdown = (countriesResponse) => {
    const countryOptions = [];
    let countriesList = [];

    // Forces synchronous execution when retrieving countries from the api, if there are no countries in the countryState.
    if (countryState.countries <= 0) {
      countriesList = countriesResponse;
    } else {
      countriesList = countryState.countries;
    }

    for (const country of countriesList) {
      countryOptions.push({
        label: country.name,
        value: country,
      });
    }

    setCountryDropdown(countryOptions);
  }

  /**
   * Compiles options list for the dropdown containing field of studies.
   */
  const buildFieldsOfStudyDropdown = (fieldsOfStudy) => {
    const fieldOfStudyOptions = [];

    for (const field of fieldsOfStudy) {
      fieldOfStudyOptions.push({
        label: field.name,
        value: field,
      });
    }

    setFieldOfStudyDropdown(fieldOfStudyOptions);
  }

  /**
   * Handle the selection of a country
   * @param {object} event The event passed on a country selection change
   */
  const handleCountrySelect = event => {
    const selectedCountry = event.value;
    setSelectedFieldOfStudy(null);
    setFieldOfStudyJobPositions([]);
    setFieldOfStudyJobPositionIds([]);
    setSelectedCountry(selectedCountry);
  };

  /**
   * Handle the selection of a the field of study
   * @param {object} event The event passed on a field of study selection change
   */
  const handleFieldOfStudySelect = async event => {
    const selectedFieldOfStudy = event.value;

    // Check for existing field_of_study_job_positions
    const selectedFieldOfStudyJobPositions = await api.get(`/fields-of-study/${selectedFieldOfStudy.id}/countries/${selectedCountry.id}/company/${companyState.selectedCompany.id}/job-positions`);

    if (selectedFieldOfStudyJobPositions.data) {
      let tempSelectedFieldOfStudyJobPositions = [];
      let tempSelectedFieldOfStudyJobPositionIds = [];

      for (const selectedFieldOfStudyJobPosition of selectedFieldOfStudyJobPositions.data) {
        tempSelectedFieldOfStudyJobPositions.push({
          label: selectedFieldOfStudyJobPosition.jobPosition.jobFamily,
          value: selectedFieldOfStudyJobPosition.jobPosition.id,
        });

        tempSelectedFieldOfStudyJobPositionIds.push({
          id: selectedFieldOfStudyJobPosition.id,
          fieldOfStudy: selectedFieldOfStudyJobPosition.fieldOfStudy.id,
          jobPosition: selectedFieldOfStudyJobPosition.jobPosition.id,
        });
      }

      setFieldOfStudyJobPositions(tempSelectedFieldOfStudyJobPositions)
      setFieldOfStudyJobPositionIds(tempSelectedFieldOfStudyJobPositionIds);
    }

    setSelectedFieldOfStudy(selectedFieldOfStudy);
  };

  /**
   * Handle the selection of a job position
   * @param {array} selectedJobPositions An array of the selected job positions
   */
  const handleJobPositionSelect = selectedJobPositions => {
    let tempSelectedFieldOfStudyJobPositions = [];
    let tempSelectedFieldOfStudyJobPositionIds = [];

    if (selectedJobPositions) {
      for (const selectedJobPosition of selectedJobPositions) {
        tempSelectedFieldOfStudyJobPositions.push({
          label: selectedJobPosition.label,
          value: selectedJobPosition.value,
        });

        tempSelectedFieldOfStudyJobPositionIds.push({
          company: companyState.selectedCompany.id,
          country: selectedCountry.id,
          fieldOfStudy: selectedFieldOfStudy.id,
          jobPosition: selectedJobPosition.value,
        });
      }
    }

    setFieldOfStudyJobPositionIds(tempSelectedFieldOfStudyJobPositionIds);
    setFieldOfStudyJobPositions(tempSelectedFieldOfStudyJobPositions);
  };

  /**
   * Handle the click of the save button
   */
  const handleSaveClick = async () => {
    setIsLoading(true);
    const mapJobPositionsPayload = {
      fieldOfStudyJobPositionIds,
      company: companyState.selectedCompany.id,
      country: selectedCountry.id,
      fieldOfStudy: selectedFieldOfStudy.id,
    };

    await api.post(`/fields-of-study/map-job-positions`, mapJobPositionsPayload);
    setIsLoading(false);
  };

  return (
      isLoading
      ? <Loading />
      : <Fragment>
          <Header showClientNavigation ={true} showImmigrationNavigation={false} displayName={companyState.selectedCompany.name} link={"/select-company"} />
          {
            countryState.countries.length > 0
            ? <div className="container">
              <h3 className="text-secondary text-bold pb-4">Map Job Positions to Fields of Study</h3>
                <div className="row d-flex justify-content-center">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="country-select" className="text-secondary" >SELECT COUNTRY</label>
                      <Select
                        value={selectedCountry !== null && {value: selectedCountry, label: selectedCountry.name}}
                        onChange={handleCountrySelect}
                        options={countryDropdown}
                        id="country-select"
                      />
                    </div>
                  </div>
                </div>
                {
                  selectedCountry &&
                  <div className="row d-flex justify-content-center">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="field-of-study-select" className="text-secondary">SELECT FIELD OF STUDY</label>
                        <Select
                          value={selectedFieldOfStudy !== null && {value: selectedFieldOfStudy, label: selectedFieldOfStudy.name}}
                          options={fieldOfStudyDropdown}
                          onChange={handleFieldOfStudySelect}
                          id="field-of-study-select"
                        />
                      </div>
                    </div>
                  </div>
                }
                {
                  selectedFieldOfStudy &&
                  <Fragment>
                    <div className="row d-flex justify-content-center">
                      <div className="col-6">
                        <label className="text-secondary">SELECTED FIELD OF STUDY: {selectedFieldOfStudy.name}</label>
                        <Select
                          isMulti
                          onChange={handleJobPositionSelect}
                          options={jobPositionsDropdown}
                          value={fieldOfStudyJobPositions}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center mt-3">
                      <button className="btn btn-primary" onClick={handleSaveClick}>Save</button>
                    </div>
                  </Fragment>
                }
              </div>
            : <div className="container">
                <p>A problem occured retrieving the countries, please return to the landing page and try again.</p>
              </div>
          }
        </Fragment>
  )
};

export default JobPositionToFieldOfStudyMapping;
