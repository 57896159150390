import React, {useContext, useState, useEffect} from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {Link, useParams} from 'react-router';
import {api} from '../../api';
import Header from '../../general/navigation/Header';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Loading from '../../general/components/Loading';
import { CountryContext } from '../../context/CountryContext';

const WorkPermitsList = () => {
  // Initialise default values
  const { countryId } = useParams();
  const [countryState, countryDispatch] = useContext(CountryContext);
  const [workPermits, setWorkPermits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workPermitToDecommission, setworkPermitToDecommission] = useState(null);

  // Tooltip status
  // const [editTooltipOpen, setEditTooltipOpen] = useState(false);
  // const [decommissionTooltipOpen, setDecommissionTooltip] = useState(false);

  useEffect(() => {
    // Set the selected country again if it becomes unset during page refresh.
    setIsLoading(true);
    if (!countryState.selectedCountry) {
      fetchSelectedCountryWorkPermits();
    } else {
      fetchWorkPermitData();
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryState.selectedCountry]); // Reload useEffect() when countryState is updated, to ensure async countryDispatch updates are passed through.

  /**
   * If the selected country becomes unset during page refresh, retrieves the data based on countryId from the URL params, before
   * retrieving the work permits.
   */
  const fetchSelectedCountryWorkPermits = async () => {
    const countryRepsonse = await api.get(`/countries`);
    const selectedCountry = countryRepsonse.data.filter(country => country.id.toString() === countryId.toString())[0];

    countryDispatch({
      type: 'SELECT_COUNTRY',
      selectedCountry: selectedCountry,
    });
  };

  /**
   * Retrieve the work permits from  the database according to the current selected country ID and region ID.
   */
  const fetchWorkPermitData = async () => {
    const workPermitsResponse = await api.get(`/countries/${countryState.selectedCountry.id}/regions/${countryState.selectedCountry.regions[0].id}/work-permits`);
    setWorkPermits(workPermitsResponse.data);
    setIsLoading(false);
  };

  /**
   * Formats given date into the format: DD-MM-YYYY HH:MM:SS
   * @param {object} date the date and timestamp of the most recent revision of the work permit.
   */
  const formatDate = (date) => {
    date = new Date(date)
    return appendLeadingZeroes(date.getDate()) + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + date.getFullYear() + " " +
      appendLeadingZeroes(date.getHours()) + ":" + appendLeadingZeroes(date.getMinutes()) + ":" + appendLeadingZeroes(date.getSeconds());
  };

  /**
   * Append a leading zero to single digit integers which from the date object.
   * @param {integer} n
   */
  const appendLeadingZeroes = (n) => {
    if (n <= 9) {
      return "0" + n;
    }
    return n
  };

  const decomissionWorkPermit = () => {
    setIsLoading(true);

    api.delete(`/work-permits/${workPermitToDecommission}`)
      .then(() => {
        fetchWorkPermitData();
        toggleDecommissionModal();
      })
      .then(() => {
        setworkPermitToDecommission(null)
      });
  };

  const toggleDecommissionModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const toggleEditTooltip = () => setEditTooltipOpen(!editTooltipOpen);

  // const toggleDecommissionTooltip = () => setDecommissionTooltip(!decommissionTooltipOpen);

  return (
    <React.Fragment>
      {countryState.selectedCountry &&
      <React.Fragment>
      <Header showClientNavigation ={false} showImmigrationNavigation={true} displayName={countryState.selectedCountry.name} link={"/select-country"}/>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 d-flex justify-content-between">
            <h3 className="mr-5 gw__section-headings">Work Permits</h3>
            <Link to={`/countries/${countryState.selectedCountry.id}/work-permits/create`}>
              <button type="button" className="btn btn-outline-green">
                <AddCircleOutlineOutlinedIcon className="gw__add-icon"/>
                NEW PERMIT
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 mb-5">
            <table className="table table-hover mt-5 mb-5">
              <thead>
                <tr>
                  <th scope="col">PERMIT NAME</th>
                  <th scope="col">LAST UPDATED</th>
                  <th></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  workPermits.map((workPermit, index) => {
                    return (
                      <tr className="align-content-center" key={index}>
                        <td>{workPermit.id + ' - ' + workPermit.workPermitRevisions[0].name + (workPermit.workPermitRevisions[0].deloittePermitId && " - "+ workPermit.workPermitRevisions[0].deloittePermitId)}</td>
                        <td>{formatDate(workPermit.workPermitRevisions[0].publishedAt)}</td>
                        <td>
                          <Link className="gw__nav_links gw__edit-permit-button" to={`/countries/${countryState.selectedCountry.id}/work-permits/${workPermit.id}/edit`}>
                            <svg id="EditTooltipExample" width="2em" height="2em" viewBox="0 0 18 18" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"/>
                              <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"/>
                            </svg>
                            {/* <UncontrolledTooltip placement="top" target="EditTooltipExample">
                              Edit
                          </UncontrolledTooltip> */}
                            {/* <button type="button" className="btn btn-primary float-right mx-2">Edit</button> */}
                          </Link>
                        </td>
                        <td>
                          <HighlightOffOutlinedIcon className="gw__delete-permit-icon" onClick={() => { setworkPermitToDecommission(workPermit.id); toggleDecommissionModal(); }} id="UncontrolledTooltipExample"/>
                          {/* <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample">
                            Decommission
                          </UncontrolledTooltip> */}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleDecommissionModal}>
            <ModalHeader>
              Decommission Permit
            </ModalHeader>
            <ModalBody>
               <b>Are you sure you want to decommission this work permit?</b><br />
               This permit and its associated data will be completely deleted across all clients.
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={() => toggleDecommissionModal()}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={decomissionWorkPermit}>Yes, Remove</button>
            </ModalFooter>
        </Modal>
      </React.Fragment>
      }
      {isLoading &&
        <center><Loading/></center>
      }
    </React.Fragment>
  );
};
export default WorkPermitsList;
