import Underline from "@tiptap/extension-underline";
import { generateHTML } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import BulletList from "@tiptap/extension-bullet-list";

export const parseToHtml = (content) => {
  return generateHTML(content, [
    StarterKit,
    Underline,
    Link,
    BulletList,
  ]);
}
