export const handleRichTextInput = (draftJsContent) => {
    if (!draftJsContent) return;
    if (typeof draftJsContent === 'string') {
        try{
            draftJsContent = JSON.parse(draftJsContent);
        } catch(e) {
            return {
                type: 'doc',
                content: [{
                    type: 'paragraph',
                    content: [{ type: 'text', text: draftJsContent }]
                }]
            }
        }
    }
    if(draftJsContent.blocks === undefined) {
        return draftJsContent;
    }
    const createMarksFromInlineStyles = (inlineStyleRanges, entityRanges, entityMap) => {
        const marks = [];

        inlineStyleRanges.forEach(style => {
            if (style.style === 'BOLD') {
                marks.push({ type: 'bold' });
            }
            if (style.style === 'ITALIC') {
                marks.push({ type: 'italic' });
            }
            if (style.style === 'UNDERLINE') {
                marks.push({ type: 'underline' });
            }
        });

        if (entityRanges.length > 0) {
            const entityRange = entityRanges[0];
            const entity = entityMap[entityRange.key];
            if (entity.type === 'LINK') {
                marks.push({
                    type: 'link',
                    attrs: {
                        href: entity.data.url,
                        target: entity.data.target,
                        rel: 'noopener noreferrer nofollow',
                        class: null
                    },
                });
            }
        }
        return marks;
    }

    const processDraftJsBlock = (block, entityMap) => {
        if (block.type === 'header-one') {
            return {
                type: 'heading',
                attrs: { level: 1 },
                content: [{ type: 'text', text: block.text }]
            };
        }

        const content = [];
        let lastIndex = 0;

        const sortedEntityRanges = [...block.entityRanges].sort((a, b) => a.offset - b.offset);

        sortedEntityRanges.forEach(entityRange => {
            if (entityRange.offset > lastIndex) {
                const textBefore = block.text.slice(lastIndex, entityRange.offset);
                if (textBefore.trim()) {
                    content.push({
                        type: 'text',
                        text: textBefore,
                    });
                }
            }
            const entity = entityMap[entityRange.key];
            if (entity.type === 'LINK') {
                const linkText = block.text.slice(entityRange.offset, entityRange.offset + entityRange.length);
                if (linkText.trim()) {
                    content.push({
                        type: 'text',
                        text: linkText,
                        marks: [{
                            type: 'link',
                            attrs: {
                                href: entity.data.url,
                                target: entity.data.target || '_blank',
                                rel: 'noopener noreferrer nofollow',
                                class: null
                            }
                        }]
                    });
                }
            }
            lastIndex = entityRange.offset + entityRange.length;
        });
        if(lastIndex < block.text.length) {
            const remainingText = block.text.slice(lastIndex);
            if (remainingText.trim()) {
                content.push({
                    type: 'text',
                    text: remainingText,
                });
            }
        }

        if (content.length === 0 && block.text.trim()) {
            content.push({
                type: 'text',
                text: block.text,
                marks: createMarksFromInlineStyles(block.inlineStyleRanges, [], entityMap),
            });
        }

        if (block.type === 'unordered-list-item') {
            return {
                type: 'listItem',
                content: [{
                    type: 'paragraph',
                    content: content
                }]
            };
        }

        return {
            type: 'paragraph',
            content: content
        };
    }
    const content = [];
    let currentList = null;

    draftJsContent.blocks.forEach(block => {
        if (block.type === 'unordered-list-item') {
            if (!currentList) {
                currentList = {
                    type: 'bulletList',
                    content: []
                };
                content.push(currentList);
            }
            currentList.content.push(processDraftJsBlock(block, draftJsContent.entityMap));
        } else {
            currentList = null;
            content.push(processDraftJsBlock(block, draftJsContent.entityMap));
        }
    });

    return {
        type: 'doc',
        content: content
    };
}
