import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = (props) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function tick() {
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="gw__loading-page-container">
        <CircularProgress
        className="gw__loading-spinner"
        color="primary"
        variant="determinate"
        value={progress}
        thickness= {props.results ? 2 : 1}
        size={props.results ? 150 : 40}
        />
    </div>
  );
}

export default Loading;
