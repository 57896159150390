import { Switch } from '@mui/material';
import { withStyles } from '@mui/styles';

export const BlueSwitch = withStyles({
    switchBase: {
      color: '#8DDE43',
      '&$checked': {
        color: '#8DDE43',
      },
      '&$checked + $track': {
        backgroundColor: '#8DDE43',
      },
      '&$disabled': {
        backgroundColor: '#B8B8B8',
        color: '#B8B8B8',
      },
    },
    checked: {},
    track: {},
  })(Switch);