import React, { Fragment, useState } from 'react';
import { Link } from'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logoutUser } from '../../services/UserService';
import SettingsOutlinedIcon from  '@mui/icons-material/SettingsOutlined';

const WapaNavigation = (props) => {
  const { displayName, link } = props;
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);

  const toggleSettings = () => {
    setSettingsDropdownOpen(!settingsDropdownOpen);
  }

  const logout = () => {
    logoutUser();
  }

  return (
    <Fragment>
      <div className="d-flex heading-container">
        <Link className="gw__nav_links d-flex" to="/">
          <h1 className="gw__nav-logo mb-0">
            <span className="gw__nav-title-go">Go</span>
            <span className="gw__nav-title-work">Work</span>
            <span className="gw__nav-title-dot">.</span>
          </h1>
          <div className="pl-3 mt-3 gw__nav-subtitle" >Immigration Hub</div>
        </Link>
        {displayName &&
          <Fragment>
            <div className="pl-4 ml-4 pb-2 mt-2 mb-2"></div>
            <div className="ml-2 mt-3 gw__selected-country-client">
              {displayName}
            </div>
            <div className="gw__header-link">
              <Link className="gw__nav_links" to={link}>Change</Link>
            </div>
          </Fragment>
        }
        <div className="gw__country-client-container">
          <Link className={"mr-5 gw__nav_links gw__country-client-select" + (localStorage.getItem('countryId') && displayName ? " gw__country-client-highlight" : "")} to="/select-country">Country</Link>
          <Link className={"mr-1 gw__nav_links gw__country-client-select" + (localStorage.getItem('companyId') && displayName ? " gw__country-client-highlight" : "")} to="/select-company">Client</Link>
        </div>
        <div className="gw__settings-toggle">
          <Dropdown className="mr-3" isOpen={settingsDropdownOpen} toggle={toggleSettings}>
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              aria-expanded={settingsDropdownOpen}
              >
              <SettingsOutlinedIcon />
            </DropdownToggle>
            <DropdownMenu className="header-dropdown-menu">
              <DropdownItem>
                <Link className="wapa-name-link" to="/countries">
                  <div className="text-secondary header-dropdown-link">Manage Countries</div>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="wapa-name-link" to="/clients">
                  <div className="text-secondary header-dropdown-link">Manage Clients</div>
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link className="wapa-name-link" to="/manage/fields-of-study">
                  <div className="text-secondary header-dropdown-link">Manage Fields of Study</div>
                </Link>
              </DropdownItem>
              {/* TODO only show Logout button if user is currently logged in */}
              <DropdownItem>
                <Link className="wapa-name-link" to="/login">
                  <div className="text-secondary header-dropdown-link" onClick={logout}>Logout</div>
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <hr className="gw__header-row gw__header-shadow"></hr>
    </Fragment>
  );
}

export default WapaNavigation;
