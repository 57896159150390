import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useCallback, useState } from 'react';
import { BulletList } from '@tiptap/extension-bullet-list';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';

const INLINE_STYLES = [
  { title: 'B', style: 'bold' },
  { title: 'I', style: 'italic' },
  { title: 'U', style: 'underline' }
];

const RichTextEditor = ({ content, onContentChange }) => {
  const extensions = [
    Underline,
    StarterKit,
    BulletList,
    Link,
    Placeholder.configure({
      placeholder: 'Please avoid using > and < characters as they are not supported for security reasons',
    })
  ];

  const editor = useEditor({
    extensions: extensions,
    content: content,
    onUpdate: ({ editor }) => {
      onContentChange(editor.getJSON());
    }
  });

  const [url, setUrl] = useState('');
  const [linkInputActive, setLinkInputActive] = useState(false);

  const openLinkInput = useCallback(() => {
    setLinkInputActive(!linkInputActive);
    const previousUrl = editor.getAttributes('link').href;
    setUrl(previousUrl || '');
  }, [editor, linkInputActive]);

  const setLink = useCallback(() => {
    if (url === null) {
      return;
    }
    if (url.trim() === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    } else {
      editor.chain().focus().setLink({ href: url }).run();
    }
  }, [editor, url]);

  if (!editor) return null;


  return (
    <div className='rtf-container'>
      <div className='toolbar'>
        {INLINE_STYLES.map((style) => (
          <button
            key={style.title}
            onClick={(e) => {
              e.preventDefault();
              editor.chain().focus().toggleMark(style.style).run()
            }}
          >
            {style.title}
          </button>
        ))}
        <button
          key={"List"}
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleBulletList().run();
          }}
        >
          List
        </button>
        <button
          key={"title"}
          onClick={(e) => {
            e.preventDefault();
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }}
        >
          Title
        </button>
      </div>

      <EditorContent editor={ editor } content={ content } />
    <div>
        <button onClick={ (e)=> {e.preventDefault(); openLinkInput() }}> Add Link </button>
        <button onClick={ (e) => {e.preventDefault(); editor.chain().focus().unsetLink().run() }}> Remove Link </button>
        {linkInputActive && (
          <div>
            <input type='text' value={url} onChange={ (e)=>{setUrl(e.target.value) }} placeholder='Enter link' />
            <button onClick={(e) => {
              e.preventDefault();
              setLink();
            }}>
              Add link
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default RichTextEditor;
