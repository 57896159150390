/* eslint-disable no-unused-vars */
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { CountryContext } from '../../context/CountryContext';
import { CompanyContext } from '../../context/CompanyContext';
import { BlueSwitch } from '../../general/components/BlueSwitch.js';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading.js';
import { api } from '../../api';
import WorkPermitOutputClient from '../../client/work-permit/WorkPermitOutputClient.js';

const OrderPermits = () => {
  const [countryState, countryDispatch] = useContext(CountryContext);
  const [companyState, companyDispatch] = useContext(CompanyContext);
  const [companyId] = useState(companyState?.selectedCompany?.id || localStorage.getItem('companyId'));
  const [selectedCompany, setSelectedCompany] = useState(companyState.selectedCompany);
  const [blacklistedPermits, setBlacklistedPermits] = useState([]);
  const [workPermits, setWorkPermits] = useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPermitRankObject, setSelectedPermitRankObject] = useState(null);
  const [addManualAssessed, setAddManualAssessed] = useState([]);
  const [removeManualAssessed, setRemoveManualAssessed] = useState([]);

  useEffect(() => {
    if (companyState?.selectedCompany === null) {
        window.location.replace('/#/');
    }
    if (countryState?.countries?.length <= 0) {
        fetchCountries();
    }
    else{
        returnCountries();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCountries = async () => {
    const countriesResponse = await fetchCountries();

    if (countriesResponse) {
      countryDispatch({
        type: 'FETCH_COUNTRIES',
        countries: countriesResponse,
      });
    }
    returnCountries();
  };

  const returnCountries = () => {
    setCountries(countryState.countries);
  }

  const onClickOrder = (index, orderType) => {
    setChangesMade(true);
    setIsLoading(true);
    let updatedWorkPermits = [...workPermits];
    let permitToMoveUp = orderType === 'up' ? updatedWorkPermits[index] : updatedWorkPermits[index + 1];
    let permitToMoveDown = orderType === 'up' ? updatedWorkPermits[index - 1] : updatedWorkPermits[index];

    permitToMoveUp.rank = permitToMoveUp.rank - 1;
    permitToMoveDown.rank = permitToMoveDown.rank + 1;

    updatedWorkPermits.sort(dynamicSort('rank'));

    setWorkPermits(updatedWorkPermits);
    setIsLoading(false);
  };

  const onCountrySelected = (event) => {
    const selectedCountry = event.value;
    const regionId = selectedCountry.id;
    const selectedRegion = selectedCountry.regions.find(region => region.id.toString() === regionId.toString());
    setSelectedCountry(selectedCountry);
    setSelectedRegion(selectedRegion);
    fetchRankedPermits(selectedCountry, selectedRegion);
  };

  const fetchRankedPermits = async (selectedCountry, selectedRegion) => {
    console.log('selectedCountry',selectedCountry);
    console.log('selectedRegion', selectedRegion);
    let allPermits = await api.get(`/countries/${selectedCountry.id}/regions/${selectedRegion.id}/work-permits/${companyId}`);
    allPermits = allPermits.data;
    let rankedPermits = await api.post('/work-permits/filter-work-permit-ranks', { company: companyId, region: selectedRegion.id });
    rankedPermits = rankedPermits.data;

    for (let i = 0; i < rankedPermits.length; i++) {
      let workPermit = allPermits.find(permit => permit.id === rankedPermits[i].workPermit.id);
      if (workPermit) {
        rankedPermits[i].workPermit.name = workPermit.workPermitRevisions[0].name;
        rankedPermits[i].workPermit.deloittePermitId = workPermit.workPermitRevisions[0].deloittePermitId;
        rankedPermits[i].rank = i + 1;
        rankedPermits[i].blacklist = getPermitBlacklistingDetail(workPermit);
        rankedPermits[i].manual = getPermitManualAssessedDetail(workPermit);
      }
    }

    setWorkPermits(rankedPermits);
  };

  const getPermitBlacklistingDetail = (workPermit) => {
    let permitBlacklistInfo = { status: false };
    if (isBlacklistedPermitForCompany(workPermit)) {
      permitBlacklistInfo.id = workPermit.companyWorkPermitBlacklists[0].id;
      permitBlacklistInfo.status = true;
    }
    return permitBlacklistInfo;
  };

  const getPermitManualAssessedDetail = (workPermit) => {
    let permitManualInfo = { status: false };
    if (isManuallyAssessedForCompany(workPermit)) {
      permitManualInfo.id = workPermit.companyManuallyAssessedPermitList[0].id;
      permitManualInfo.status = true;
      permitManualInfo.content = workPermit.companyManuallyAssessedPermitList[0].output;
    }
    return permitManualInfo;
  };

  const isBlacklistedPermitForCompany = (workPermit) => {
    return workPermit.companyWorkPermitBlacklists && workPermit.companyWorkPermitBlacklists.length > 0;
  };

  const isManuallyAssessedForCompany = (workPermit) => {
    return workPermit.companyManuallyAssessedPermitList && workPermit.companyManuallyAssessedPermitList.length > 0;
  };

  const updateManualStatus = (permitRankObject) => {
    setChangesMade(true);
    let tempWorkPermits = [...workPermits];
    const newIsManualStatus = !permitRankObject.manual.status;
    tempWorkPermits.find(permit => permit.id === permitRankObject.id).manual.status = newIsManualStatus;
    setWorkPermits(tempWorkPermits);

    if (newIsManualStatus) {
      setAddManualAssessed(prev => [...prev, { workPermitId: permitRankObject.workPermit.id }]);
    } else {
      setRemoveManualAssessed(prev => [...prev, { workPermitId: permitRankObject.workPermit.id }]);
    }
  };

  const updateManuallyAssessedPermits = async () => {
    if (addManualAssessed.length > 0) {
      await api.post(`/companies/${companyId}/add-manually-assessed-permits`, addManualAssessed);
      setAddManualAssessed([]);
    }

    if (removeManualAssessed.length > 0) {
      await api.delete(`/companies/${companyId}/remove-manually-assessed-permits`, { data: removeManualAssessed });
      setRemoveManualAssessed([]);
    }
  };

  const onClickBlacklistChange = (workPermit) => {
    setChangesMade(true);
    let updatedWorkPermits = [...workPermits];

    updatedWorkPermits.forEach((permit, i) => {
      if (permit.id === workPermit.id) {
        permit.blacklist.status = !permit.blacklist.status;
        handleBlacklistingPermitChange(permit);
        sendBlacklistedPermitToBottomOfRankings(updatedWorkPermits, i);
      }
    });

    setWorkPermits(updatedWorkPermits);
  };

  const handleBlacklistingPermitChange = (workPermit) => {
    let updatedBlacklistedPermits = [...blacklistedPermits];
    const existingIndex = updatedBlacklistedPermits.findIndex(blp => blp.workPermit.id === workPermit.workPermit.id);

    if (existingIndex !== -1) {
      updatedBlacklistedPermits.splice(existingIndex, 1);
    } else {
      updatedBlacklistedPermits.push(workPermit);
    }

    setBlacklistedPermits(updatedBlacklistedPermits);
  };

  const sendBlacklistedPermitToBottomOfRankings = (workPermits, index) => {
    let workPermit = workPermits[index];

    if (workPermit.blacklist.status) {
      workPermits.splice(index, 1);
      workPermits.push(workPermit);
    }

    setWorkPermits(workPermits);
  };

  const saveBlacklistedWorkPermits = async () => {
    let permitsToBlacklist = getPermitsWithBlacklistingChangeByStatus(true);
    if (permitsToBlacklist.length > 0) {
      const url = `/companies/${companyId}/blacklist-work-permits`;
      return await api.post(url, permitsToBlacklist);
    }
    return Promise.resolve();
  };

  const saveDeBlacklistedWorkPermits = async () => {
    let permitsToDeBlacklist = getPermitsWithBlacklistingChangeByStatus(false);
    if (permitsToDeBlacklist.length > 0) {
      const url = `/companies/${companyId}/remove-work-permits-from-blacklist`;
      return await api.delete(url, { data: permitsToDeBlacklist });
    }
    return Promise.resolve();
  };

  const getPermitsWithBlacklistingChangeByStatus = (status) => {
    return blacklistedPermits.filter((blp) => blp.blacklist.status === status)
      .map((blp) => getBlacklistingChangeDto(blp));
  };

  const getBlacklistingChangeDto = (blp) => {
    return blp.blacklist.status
      ? { company: companyId, workPermit: blp.workPermit.id }
      : Number(blp.blacklist.id);
  };

  const saveChanges = async () => {
    setIsLoading(true);
    await Promise.all([saveBlacklistedWorkPermits(), saveDeBlacklistedWorkPermits()]);
    await updateManuallyAssessedPermits();
    await saveUpdatedWorkPermitRankings();

    fetchRankedPermits(selectedCountry, selectedRegion).then(() => {
      alert('Permits updated successfully.');
      setChangesMade(false);
      setBlacklistedPermits([]);
    });
  };

  const saveUpdatedWorkPermitRankings = async () => {
    let updatedWorkPermitRankings = workPermits.map((workPermit, index) => ({
      rank: index + 1,
      company: companyId,
      workPermit: workPermit.workPermit.id,
    }));

    return await api.post('/work-permits/update-work-permit-ranks', updatedWorkPermitRankings)
      .then(() => {
        setIsLoading(false);
      });
  };

  const dynamicSort = (property) => {
    return (a, b) => {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    };
  };

  const returnCountryOptions = () => {
    return countries.map(country => ({
      label: country.name,
      value: country,
    }));
  };

  const cancelChanges = () => {
    setSelectedRegion(null);
    setBlacklistedPermits([]);
  };

  const handleEditClick = (permit) => {
    setIsEditMode(true);
    setSelectedPermitRankObject(permit);
  };

  const handleCloseEdit = () => {
    setIsEditMode(false);
    setSelectedPermitRankObject(null);
  };

  const resetRegion = () => {
    setSelectedRegion(null);
    setAddManualAssessed([]);
    setRemoveManualAssessed([]);
  };

  const handleManualAssessmentUpdate = (updatedManualAssessment) => {
    const updatedWorkPermits = [...workPermits];
    const permitIndex = updatedWorkPermits.findIndex(
      permit => permit.workPermit.id === selectedPermitRankObject.workPermit.id
    );

    if (permitIndex !== -1) {
      updatedWorkPermits[permitIndex].manual = {
        ...updatedWorkPermits[permitIndex].manual,
        content: updatedManualAssessment.content,
      }
      setWorkPermits(updatedWorkPermits);
    }
  }

  return (
    <>
      {selectedCompany && <Header showClientNavigation={true} showImmigrationNavigation={false} displayName={selectedCompany.name} link="/select-company" />}
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {selectedCompany && (
            <div className="container position-relative">
              {isEditMode && selectedPermitRankObject && (
                <div className="order-permits__container bg-white">
                  <WorkPermitOutputClient
                    selectedCountry={selectedCountry}
                    selectedRegion={selectedRegion}
                    selectedPermit={selectedPermitRankObject.workPermit}
                    handleClose={handleCloseEdit}
                    manualAssessmentDetails={selectedPermitRankObject.manual}
                    onManualAssessmentUpdate={handleManualAssessmentUpdate}
                  />
                </div>
              )}
              <h3 className="text-secondary text-bold pb-4">Manage Permits</h3>
              <p className="text-danger font-weight-light pb-4">
                Please be aware newly created work permits for a country are automatically assigned the lowest rank in this section. This means it will be the last suggested permit to the client on their output unless rank is adjusted here. Please manage permits below according to client preference.
              </p>
              <p className="text-danger font-weight-light pb-4">
                <b>Important:</b> Disabling the Manually Assessed toggle for a permit will simultaneously delete any custom output text for the further assessment required results page.
              </p>
              <>
                {countries && !selectedRegion && (
                  <div className="container d-flex justify-content-center">
                    <div className="form-group map-select col-6">
                      <label htmlFor="country-select" className="text-secondary">SELECT COUNTRY TO MANAGE PERMITS</label>
                      <Select options={returnCountryOptions()} onChange={onCountrySelected} />
                    </div>
                  </div>
                )}
                {selectedRegion && (
                  <div className="d-flex w-100 justify-content-center">
                    <button className="btn btn-link mb-5" onClick={resetRegion}>Reset Selected Country</button>
                  </div>
                )}
                {selectedRegion && workPermits && (
                  <div>
                    <div className="d-flex justify-content-center">
                      <table className="table w-75 text-center">
                        <thead>
                          <tr>
                            <th>Rank</th>
                            <th className="text-left">Name</th>
                            <th>Adjust Rank</th>
                            <th>Enabled</th>
                            <th>Manually Assessed</th>
                            <th>Edit Output</th>
                          </tr>
                        </thead>
                        <tbody>
                          {workPermits.map((permit, index) => (
                            <tr className={permit.blacklist.status ? 'bg-light' : ''} key={index}>
                              <td>{index + 1}</td>
                              <td className="text-left">{permit.workPermit.name}{permit.workPermit.deloittePermitId && ` - ${permit.workPermit.deloittePermitId}`}</td>
                              <td>
                                <div className="d-flex justify-content-center">
                                  {index !== 0 && <button onClick={() => onClickOrder(index, 'up')} className="btn btn-primary btn-ranking mr-1"> &#8593;</button>}
                                  {index !== workPermits.length - 1 && <button onClick={() => onClickOrder(index, 'down')} className="btn btn-primary btn-ranking mr-1"> &#8595;</button>}
                                </div>
                              </td>
                              <td>
                                <BlueSwitch checked={!permit.blacklist.status} onChange={() => onClickBlacklistChange(permit)} />
                              </td>
                              <td>
                                <BlueSwitch checked={permit.manual.status} onChange={() => updateManualStatus(permit)} />
                              </td>
                              <td>
                                <EditOutlinedIcon className="order-permits__edit" onClick={() => handleEditClick(permit)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {changesMade && (
                      <div className="d-flex justify-content-center">
                        <button className="btn btn-secondary mr-2" onClick={saveChanges}>Save Changes</button>
                        <button className="btn btn-danger" onClick={cancelChanges}>Cancel</button>
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderPermits;
