import React, { useState, useEffect, Fragment } from 'react';
import { Collapse, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextareaAutosize from 'react-textarea-autosize';
import sanitizeHtml from 'sanitize-html';

import { api } from '../../api';
import Loading from '../../general/components/Loading';
import { BlueSwitch } from '../../general/components/BlueSwitch';
import RichTextEditor from '../../general/text-editor/RichTextEditor';
import { handleRichTextInput } from '../../utils/ConvertDraftToTiptap';

const WorkPermitOutputClient = (props) => {
  const [companyId, setCompanyId] = useState(null);
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUrlValid, setIsUrlValid] = useState(true);
  const [whitelistedUrls, setWhitelistedUrls] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [blacklistedUrls, setBlacklistedUrls] = useState([]);
  const [sectionToDeleteId, setSectionToDeleteId] = useState(null);
  const [isRemoveSectionModalOpen, setIsRemoveSectionModalOpen] = useState(false);
  const [isRemoveOutputModalOpen, setIsRemoveOutputModalOpen] = useState(false);
  const [recruiterOutputId, setRecruiterOutputId] = useState(null);
  const [showGlobalOutput, setShowGlobalOutput] = useState(true);
  const [clientName, setClientName] = useState('');
  const [clientDescription, setClientDescription] = useState('');
  const [clientDetailsChanged, setClientDetailsChanged] = useState(false);
  const [manualAssessmentOutput, setManualAssessmentOutput] = useState(props.manualAssessmentDetails);
  const [manualAssessmentOutputChanged, setManualAssessmentOutputChanged] = useState(false);

  useEffect(() => {
    const storedCompanyId = localStorage.getItem('companyId');
    setCompanyId(storedCompanyId);
  }, []);

  useEffect(() => {
    if (manualAssessmentOutput?.content) {
      setManualAssessmentOutput(prev => ({
        ...prev,
        content: handleRichTextInput(prev.content),
      }));
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run only once on mount

  useEffect(() => {
    if (props.manualAssessmentDetails?.content) {
      setManualAssessmentOutput({
        ...props.manualAssessmentDetails,
        content: handleRichTextInput(props.manualAssessmentDetails.content)
      });
    }
  }, [props.manualAssessmentDetails]);

  useEffect(() => {
    if (!props.selectedRegion || !props.selectedCountry) {
      props.handleClose(); // Use handleClose instead of direct window.location manipulation
      return;
    }

    if (companyId) {
      fetchCustomClientDetails();
      fetchSections();
      fetchWhitelistedUrls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, props.selectedRegion, props.selectedCountry, props.handleClose]);

  const fetchWhitelistedUrls = async () => {
    const res = await api.get('/whitelist');
    setWhitelistedUrls(res.data);
  };

  const fetchSections = async () => {
    const recruiterOutputResponse = await api.get(`/work-permits/${props.selectedPermit.id}/recruiter-output/company/${companyId}`);
    let recruiterOutputCompanySettings = true;

    if(recruiterOutputResponse.data) {
      const recruiterOutputCompanySettingsResponse = await api.get(`/work-permits/${props.selectedPermit.id}/recruiter-output/${recruiterOutputResponse.data.id}/company/${companyId}`);

      if (recruiterOutputCompanySettingsResponse.data) {
        recruiterOutputCompanySettings = recruiterOutputCompanySettingsResponse.data.isGlobalSectionsActive;
      }
    }

    const newSections = [];

    if (recruiterOutputResponse.data.sections) {
      recruiterOutputResponse.data.sections.forEach(section => {
        section.content = handleRichTextInput(section.content);

        newSections.push({
          id: section.id,
          staticTitle: section.title,
          title: section.title,
          content: section.content,
          isCollapseOpen: false,
          isSectionDirty: false,
        });
      });
    }

    setIsLoading(false);
    setSections(newSections);
    setRecruiterOutputId(recruiterOutputResponse.data.id);
    setShowGlobalOutput(recruiterOutputCompanySettings);
  };

  const fetchCustomClientDetails = async () => {
    const clientDetailsResponse = await api.get(`/work-permits/${props.selectedPermit.id}/companies/${companyId}`);

    if (clientDetailsResponse && clientDetailsResponse.data) {
      let tempText = clientDetailsResponse.data.description;
      var regex = /<br\s*[/]?>/gi;
      tempText = tempText.replace(regex, '\n');
      const result = sanitizeHtml(tempText);
      clientDetailsResponse.data.description = result;

      setClientName(clientDetailsResponse.data.name);
      setClientDescription(clientDetailsResponse.data.description);
    }
  };

  const toggleCollapse = (index) => {
    const newSections = [...sections];
    newSections[index].isCollapseOpen = !newSections[index].isCollapseOpen;
    setSections(newSections);
  };

  const handleSectionTitle = (event, index) => {
    const newSections = [...sections];
    newSections[index][event.target.name] = event.target.value;
    newSections[index].isSectionDirty = true;
    setSections(newSections);
  };

  const handleRichTextFormatInput = (section, editorText) => {
    const newSections = [...sections];
    const sectionToEdit = newSections.find(stateSection => stateSection.content === section.content);
    sectionToEdit.content = handleRichTextInput(JSON.stringify(editorText));
    sectionToEdit.isSectionDirty = true;
    setSections(newSections);
  }

  // eslint-disable-next-line no-unused-vars
  const handleManualAssessmentOutputInput = (manualAssessmentOutput, editorText) => {
    setManualAssessmentOutput(prev => ({
      ...prev,
      content: handleRichTextInput(editorText),
    }));
    setManualAssessmentOutputChanged(true);
  }

  const addSection = () => {
    const newSections = [...sections];

    newSections.push({
      staticTitle: 'Enter a new title below',
      title: '',
      content: '',
      isCollapseOpen: true,
      isSectionDirty: true,
    });

    setSections(newSections);
  };

  const onClickSave = () => {
    setIsLoading(true);
    saveChanges();
  };

  const onClickBack = () => {
    props.handleClose();
  };

  const setClientNameHandler = (e) => {
    setClientName(e.target.value);
    setClientDetailsChanged(true);
  };

  const setClientDescriptionHandler = (e) => {
    setClientDescription(e.target.value);
    setClientDetailsChanged(true);
  };

  const saveChanges = async () => {
    let replaceLineBreakText = clientDescription;
    replaceLineBreakText = replaceLineBreakText.replace(/(?:\r\n|\r|\n)/g, '<br />');
    const result = sanitizeHtml(replaceLineBreakText);
    setClientDescription(result);

    if (clientDetailsChanged) {
      const customDetails = {
        name: clientName,
        description: clientDescription,
        company: companyId,
        workPermit: props.selectedPermit.id,
      };

      if (clientName === '' && clientDescription === '') {
        await api.delete(`/work-permits/${props.selectedPermit.id}/companies/${companyId}`);
      } else {
        await api.post(`/work-permits/companies/`, customDetails);
      }
    }

    if (manualAssessmentOutputChanged) {
      const manualAssessmentDetails = {
        id: manualAssessmentOutput.id,
        output: JSON.stringify(manualAssessmentOutput.content),
      };
      await api.patch(`companies/${companyId}/update-manually-assessed-permits`, manualAssessmentDetails);

      if (props.onManualAssessmentUpdate) {
        props.onManualAssessmentUpdate(manualAssessmentOutput);
      }
    }

    for (const section of sections) {
      if (section.isSectionDirty) {
        const sectionToPost = {
          title: section.title,
          content: JSON.stringify(section.content),
          companyId: companyId,
        };

        if (section.id) {
          await api.patch(`/work-permits/${props.selectedPermit.id}/recruiter-output/${recruiterOutputId}/sections/${section.id}`, sectionToPost);
        } else {
          await api.post(`/work-permits/${props.selectedPermit.id}/recruiter-output/${recruiterOutputId}/sections`, sectionToPost);
        }
      }
    }

    props.handleClose();
  };

  const handleRemoveSectionClick = (section, index) => {
    if (section.id) {
      setSectionToDeleteId(section.id);
      setIsRemoveSectionModalOpen(true);
    } else {
      const newSections = [...sections];
      newSections.splice(index, 1);
      setSections(newSections);
    }
  };

  const handleRemoveManualOutput = () => {
    setIsRemoveOutputModalOpen(true);
  };

  const removeSection = async () => {
    setIsLoading(true);
    await api.delete(`/work-permits/${props.selectedPermit.id}/recruiter-output/${recruiterOutputId}/sections/${sectionToDeleteId}`);
    toggleModal();
    setSectionToDeleteId(null);
    fetchSections();
  };

  const removeManualOutput = async () => {
    const manualAssessmentDetails = {
      id: manualAssessmentOutput.id,
      output: '',
    };

    const manualAssessmentState = { ...manualAssessmentOutput };
    manualAssessmentState.content = '';

    setIsLoading(true);
    await api.patch(`companies/${companyId}/update-manually-assessed-permits`, manualAssessmentDetails);
    toggleDeleteOutputModal();
    setIsLoading(false);
    setManualAssessmentOutput(manualAssessmentState);
  };

  const toggleModal = () => {
    setIsRemoveSectionModalOpen(!isRemoveSectionModalOpen);
  };

  const toggleDeleteOutputModal = () => {
    setIsRemoveOutputModalOpen(!isRemoveOutputModalOpen);
  };

  const onClickToggleGlobalOutput = async () => {
    const newValue = !showGlobalOutput;
    setShowGlobalOutput(newValue);

    const dataToPost = {
      recruiterOutput: recruiterOutputId,
      company: companyId,
      isGlobalSectionsActive: newValue,
    };
    await api.post(`/work-permits/${props.selectedPermit.id}/recruiter-output/company/`, dataToPost);
  };

  const isContentValid = async (content) => {
    if (whitelistedUrls.length === 0) {
      const urls = await api.get("/whitelist").then((res) => res.data);
      setWhitelistedUrls(urls);
    }
    if (!content) return false;
    for (const element of content) {
      console.log("ELEMENT:", element.type);
      switch (element.type) {
        case "text":
          if (element.text.includes("<") || element.text.includes(">"))
            return false;
          if (element.marks) {
            const contentValid = await isContentValid(element.marks);
            if (!contentValid) return false;
          }
          break;
        case "link":
          if (!whitelistedUrls.includes(element.attrs.href)) {
            setIsUrlValid(false);
            return false;
          }
          break;
        default:
          const contentValid = await element.content ? isContentValid(element.content) : true;
          if (!contentValid) return false;
          continue;
      }
    }
    return true;
  }

  const isWorkPermitOutputValid = () => {
    if (!isUrlValid) return false;

    const hasDirtySections = sections.some(section =>
      section.isSectionDirty && section.content && isContentValid(section.content.content)
    );

    return hasDirtySections || clientDetailsChanged || manualAssessmentOutputChanged;
  };

  return (
    isLoading ? <Loading /> :
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="font-weight-bold">
              <span className="text-secondary">Selected Permit: </span>
              <span>{props.selectedPermit.name + (props.selectedPermit.deloittePermitId && " - "+ props.selectedPermit.deloittePermitId)}</span>
            </h2>
            <hr className="mt-4" />
            <h3 className="text-secondary font-weight-bold output-header mt-4">Custom client details</h3>
          </div>
          <div className="col-sm-6">
            <p className="output-paragraph">
              Set a client specific name and description for the work permit. This will override any default name and/or description.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="wp-name">Name</label>
              <input
                id="wp-name"
                name="wp-name"
                className="form-control"
                type="text"
                defaultValue={clientName}
                onChange={(event) => setClientNameHandler(event)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="wp-description">Description</label>
              <TextareaAutosize
                className="form-control text-area-sizing"
                type="text"
                name="wp-description"
                id="wp-description"
                value={clientDescription}
                onChange={(event) => setClientDescriptionHandler(event)}
              />
            </div>
          </div>
        </div>

        {manualAssessmentOutput?.status &&
          <Fragment>
            <div className="row">
              <div className="col-sm-12">
                <hr className="mt-4" />
                <h3 className="text-secondary font-weight-bold output-header mt-4">Manual assessment output</h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6">
                <p className="output-paragraph">
                  Configure any message which should appear on the 'Full Assessment Required' page, instead of the default text. Links may also be
                  imbeded to words with their full 'http://' address.
                </p>
              </div>
            </div>
            <RichTextEditor
              content={manualAssessmentOutput.content}
              handleRichTextFormatInput={(editorText) => handleManualAssessmentOutputInput(manualAssessmentOutput, editorText)}
              onContentChange={(editorText) => handleManualAssessmentOutputInput(manualAssessmentOutput, editorText)}
              key={`editor-${manualAssessmentOutput.id}`} // Add key to force re-render when content changes
            />
            <button className="btn btn-danger mt-4" onClick={() => handleRemoveManualOutput()}>Remove Output</button>
          </Fragment>
        }

        <div className="row pt-4">
          <div className="col-sm-12">
            <hr className="mt-4" />
            <h3 className="text-secondary font-weight-bold output-header mt-4">Custom client output sections</h3>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-sm-6">
            <p className="output-paragraph">
              In addition to work permit specific output, we will also supply the recruiter with a client specific
              information for each work permit. This information is optional and will be shown <b>in addition to </b>
              the work permit output.
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <hr/>
            <FormControlLabel
              control={
                <BlueSwitch checked={showGlobalOutput} onChange={() => onClickToggleGlobalOutput()}/>
              }
              label="Show global work permit outputs"
            />
            <hr/>
          </div>
        </div>
        {
          props.selectedPermit && sections &&
          <Fragment>
            <div className="row">
              {
                sections.map((section, index) => {
                  return (
                    <div key={index} className="col-sm-12">
                      <hr className="mt-4" />
                      <div className="d-flex justify-content-between align-items-center" onClick={() => toggleCollapse(index)}>
                        <div className="output-section-header">{section.staticTitle}</div>
                        <div className="chevron"></div>
                      </div>
                      <Collapse className="mt-4 collapse-menu-item" isOpen={sections[index].isCollapseOpen}>
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          maxLength={255}
                          value={sections[index].title}
                          onChange={(event) => handleSectionTitle(event, index)}
                          placeholder="Please enter a title for the section"
                        />
                        <RichTextEditor content={section.content} handleRichTextFormatInput={(editorText) => handleRichTextFormatInput(section, editorText)} onContentChange={(editorText)=> handleRichTextFormatInput(section, editorText) } />
                        <button className="btn btn-danger mt-4" onClick={() => handleRemoveSectionClick(section, index)}>Remove Section</button>
                      </Collapse>
                    </div>
                  )
                })
              }
            </div>
            <div className="row mt-4">
              <div className="col-sm-12">
                <button type="button" className="btn btn-secondary" onClick={addSection}>Add Section</button>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-sm-12">
                <button disabled={!isWorkPermitOutputValid()} type="button" className="btn btn-primary" onClick={onClickSave}>Save Changes</button>
                <button type="button" className="btn btn-link" onClick={onClickBack}>Go Back</button>
              </div>
            </div>
          </Fragment>
        }
      </div>
      <Modal isOpen={isRemoveSectionModalOpen} toggle={toggleModal} className={props.className}>
        <ModalBody>
          Are you sure you want to remove this section?
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
          <button type="button" className="btn btn-danger" onClick={removeSection}>Remove</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={isRemoveOutputModalOpen} toggle={toggleDeleteOutputModal} className={props.className}>
        <ModalBody>
          Are you sure you want to remove the custom output?
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary" onClick={toggleDeleteOutputModal}>Close</button>
          <button type="button" className="btn btn-danger" onClick={removeManualOutput}>Remove</button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default WorkPermitOutputClient;
