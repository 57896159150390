import React, { useState, useEffect } from "react";
import Select from "react-select";
import { api } from "../../api";

const JobMappingRow = ({
  code,
  companyId,
  selectedCompany,
  selectedCountry,
}) => {
const [mappedCodes, setMappedCodes] = useState([]);
const [showMappedCodes, setShowMappedCodes] = useState([]);

useEffect(() => {
  setShowMappedCodes(returnDefaultPositions(code))
  // eslint-disable-next-line
},[]);


const returnDefaultPositions = (codeId) => {
  const allCompanyPositions = returnPositions();
  const countryCode = codeId;
  const defaultPositions = [];

  if (countryCode.jobPositions) {
    for (const position of countryCode.jobPositions) {
      let currentlyMappedPosition = allCompanyPositions.find(
        (companyPosition) => companyPosition.position.id === position.id
      );
      defaultPositions.push(currentlyMappedPosition);
    }
  }
  return defaultPositions;
};

const returnPositions = () => {
  const options = [];
  for (const position of selectedCompany.jobPositions) {
    options.push({
      value: position.jobFamily,
      label: position.jobFamily,
      position: position,
    });
  }

  return options;
};

  const onSelectJobPosition = (event, jobCodeId) => {
    const currentlyMappedCodes = mappedCodes;
    let jobCodeIndex = -1;

    if (currentlyMappedCodes.length > 0) {
      jobCodeIndex = currentlyMappedCodes.findIndex(
        (mappedCode) => mappedCode.jobCode.toString() === jobCodeId.toString()
      );
    }

    const positions = [];
    if (event) {
      for (const selectedPosition of event) {
        positions.push(selectedPosition.position);
      }
    }

    if (jobCodeIndex >= 0) {
      currentlyMappedCodes[jobCodeIndex] = {
        jobCode: jobCodeId,
        positions: positions,
      };
    } else {
      currentlyMappedCodes.push({ jobCode: jobCodeId, positions: positions });
    }
    setShowMappedCodes(event);
    setMappedCodes(currentlyMappedCodes);
  };

  const onClickSaveMapping = (codeId) => {
    const currentlyMappedCodes = mappedCodes;
    const mappingToSaveIndex = currentlyMappedCodes.findIndex(
      (mappedCode) => mappedCode.jobCode.toString() === codeId.toString()
    );
    if (mappingToSaveIndex >= 0) {
      const positions = { positions: [] };
      for (const position of currentlyMappedCodes[mappingToSaveIndex]
        .positions) {
        positions.positions.push({ id: position.id });
      }
      api
        .patch(
          "/countries/" +
            selectedCountry.id +
            "/job-codes/" +
            currentlyMappedCodes[mappingToSaveIndex].jobCode +
            "/company/" +
            companyId +
            "/map-job-positions",
          positions
        )
        .then((response) => {
          alert("Positions mapped successfully");
          currentlyMappedCodes.splice(mappingToSaveIndex, 1);
          setMappedCodes(currentlyMappedCodes);
        });
    } else {
      alert("No changes have been made to this job code.");
    }
  };

  const handlePastedValue = (event, codeId) => {
    let pushTo = [];
    if (event.indexOf(";") > 0) {
      let splitJobFamilyArray = event.split(";");
      for (const jobFam of splitJobFamilyArray) {
        const valueToAdd = returnPositions().find(
          (c) => c.value.toLowerCase().trim() === jobFam.toLowerCase().trim()
        );
        if (valueToAdd) {
          pushTo.push({
            label: valueToAdd.position.jobFamily,
            position: valueToAdd.position,
            value: valueToAdd.position.jobFamily,
          });
        }
      }
      onSelectJobPosition(pushTo, codeId);
      onClickSaveMapping(codeId)
    }
  };
  
  return (
    <React.Fragment>
      <tr>
        <td className="text-secondary">{code.name}</td>
        <td>
          <Select
            isMulti
            onInputChange={(e) => handlePastedValue(e, code.id)}
            onChange={(event) => onSelectJobPosition(event, code.id)}
            options={returnPositions()}
            value={showMappedCodes}
          />
        </td>
        <td>
          <button
            type="button"
            onClick={() => onClickSaveMapping(code.id)}
            className="btn btn-primary mx-2"
          >
            Save
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default JobMappingRow;
