
import React, { useEffect, useContext ,useState } from 'react';
import Select from 'react-select';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import { BlueSwitch } from '../../general/components/BlueSwitch.js';
import Header from '../../general/navigation/Header';
import { api } from '../../api';
import { Fragment } from 'react';
import { fetchRequirementBlacklist, blacklistRequirement, unblacklistRequirement } from '../../services/CompanyService';
import CircularProgress from '@mui/material/CircularProgress';
import { CountryContext } from '../../context/CountryContext';
import { CompanyContext } from '../../context/CompanyContext';

const ManageCompanyRequirements = () => {
  
  // eslint-disable-next-line no-unused-vars
  const [countryState, countryDispatch] = useContext(CountryContext);
    // eslint-disable-next-line no-unused-vars
  const [companyState, companyDispatch] = useContext(CompanyContext);
  const [companyId] = useState(companyState?.selectedCompany?.id || localStorage.getItem('companyId'));
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [requirementBlacklist, setRequirementBlacklist] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [countries, setCountries] = useState();
  const [requirements, setRequirements] = useState([]);
  const [inputs, setInputs] = useState({});
  const [requirementToDecommission, setRequirementToDecommission] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (companyState?.selectedCompany === null || countryState?.countries?.length <=0) {
        window.location.replace('/#/');
    }
    setSelectedCompany(companyState.selectedCompany);
    setCountries(countryState.countries);
   
    fetchCompanyRequirementBlacklist(companyId);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    // fetch the requirements each time the country is updated.
    useEffect(() => {
      if (selectedCountry && selectedRegion) {
        fetchRequirements();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountry, selectedRegion]);
  
  const fetchCompanyRequirementBlacklist = async (companyId) => {
    setIsLoading(true);
    const requirementBlacklist = await fetchRequirementBlacklist(companyId);
    if(requirementBlacklist){
      setIsLoading(false);
      setRequirementBlacklist(requirementBlacklist);
    } 
  };

  const fetchRequirements = async () => {
    const response = await api.get(`/countries/${selectedCountry.id}/regions/${selectedRegion.id}/requirements/company/${companyId}`);

    const inputArr = {}
    if (response && response.data) {
      response.data.map((req, index) => {
        inputArr[req.id] = {
          'question': req.requirementRevisionsCompany[0] ? req.requirementRevisionsCompany[0].question : '',
          'description': req.requirementRevisionsCompany[0] ? req.requirementRevisionsCompany[0].description : '',
        }
        req.isBlacklisted = isRequirementBlacklisted(req.id);
        return null;
      });
      setInputs(inputArr);
      setRequirements(response.data);
    }
  }
  
  const isRequirementBlacklisted = (requirementId) => {
    let isBlacklisted = false;
    if (requirementBlacklist) {
      const blacklistedRequirement = requirementBlacklist.find(item => item.id === requirementId);
      isBlacklisted = blacklistedRequirement ? true : false;
    }
    return isBlacklisted;
  };

  const getCountriesObjectArray = () => {
    const countryOptions = [];

    for (let country of countries) {
      countryOptions.push({
        label: country.name,
        value: country,
      });
    }

    return countryOptions;
  }

  const handleSelectCountry = (e) => {
    let regionId = e.value.id;
    let country = e.value;
    let selectedRegion = country.regions.find(region => region.id.toString() === regionId.toString());
    setRequirements([]);
    setInputs([]);
    setSelectedRegion(selectedRegion);
    setSelectedCountry(country);
  }

  const handleFormChange = (e, reqId, inputName) => {
    const tempInputs = { ...inputs }
    tempInputs[`${reqId}`][inputName] = e.target.value;

    setInputs(tempInputs);
  }

  const toggleDecommissionModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const saveRequirement = (reqToSave) => {
    let requirementRevisionCompanyInputs = inputs[reqToSave.id];

    api.post(`/requirements/${reqToSave.id}/company/${companyId}`, requirementRevisionCompanyInputs)
      .then(res => {
        if (res && res.status === 201) {
          // update requirementRevisionCompany in requirement
          const reqTemp = requirements.slice(0);
          const reqToUpdate = reqTemp.find(el => el.id === reqToSave.id);
          reqToUpdate.requirementRevisionsCompany[0] = res.data;
          setRequirements(reqTemp);
        }
      })
      .catch(error => { 
        // TODO: Error handling with toast
        console.error(error);
      });
  }

  const decommissionRequirement = () => {
    if (requirementToDecommission) {
      api.delete(`/requirements/${requirementToDecommission.id}/company/${companyId}/${requirementToDecommission.requirementRevisionsCompany[0].id}`)
        .then((res) => {
            if (res && res.status === 200) {
              // update requirementRevisionCompany in requirement
              const reqTemp = requirements.slice(0);
              const reqToUpdate = reqTemp.find(el => el.id = requirementToDecommission.id);
              reqToUpdate.requirementRevisionsCompany[0] = res.data;
              setRequirements(reqTemp);
  
              // update inputs
              const inputsToUpdate = { ...inputs };
              inputsToUpdate[`${requirementToDecommission.id}`].question = "";
              inputsToUpdate[`${requirementToDecommission.id}`].description = "";
              setInputs(inputsToUpdate);

              toggleDecommissionModal();
            }
          }
        )
        .catch(error => { 
          // TODO: Error handling with toast
          console.error(error);
        });
    } else {
      console.log('error');
    }
  }

  /**
   * Updates the blacklist table according to the status of the switch, i.e. add or delete.
   * @param {Requirement} requirement 
   */
  const toggleBlacklisting = async (requirement) => {
    let response;
    // If the requirement is curently not blacklisted - activating the switch will disable it.
    if (!requirement.isBlacklisted) {
      response = await blacklistRequirement(companyId, requirement.id);
    } else {
      response = await unblacklistRequirement(companyId, requirement.id);
    }
    if (response) {
      requirement.isBlacklisted = !requirement.isBlacklisted;
    } else {
      alert('An error occured with the request.');
    }
    fetchCompanyRequirementBlacklist(companyId);
  };

  const isDecommissionDisabled = (req) => {
    const ip = inputs[req.id];
    const reqRevisionCompany = req.requirementRevisionsCompany[0];

    if (
      !ip || !reqRevisionCompany ||
      ((ip.question === "" && ip.description === "") &&
      (reqRevisionCompany.question === "" && reqRevisionCompany.description === ""))
    ) {
      return true;
    }

    return false;
  }

  const isSaveDisabled = (req) => {
    const ip = inputs[req.id];
    const reqRevisionCompany = req.requirementRevisionsCompany[0];

    if (!ip || (ip.question === "" && ip.description === "")) {
      return true;
    }

    if (!!reqRevisionCompany && !!reqRevisionCompany.question && (ip.question === reqRevisionCompany.question && ip.description === reqRevisionCompany.description)) {
      return true
    }

    return false;
  }

  return (
    <Fragment>
    {
      selectedCompany &&
      (
        <Fragment>

          <Header showClientNavigation={true} showImmigrationNavigation={false} displayName={selectedCompany.name} link={"/select-company"}/>

          <div className="container position-relative">
          <h3 className="gw__section-headings pb-4">Manage Requirements</h3>
            <p className="text-danger font-weight-light pb-4">
              Set client specific questions and descriptions for each custom requirement added to a country assessment.
              If left blank the default question and description will be output to the client.
            </p>
            
            {
              countries && !selectedRegion &&
              (
                isLoading ? <div style={{display: "flex", justifyContent:"center"}}><CircularProgress /></div> : <div className="container d-flex justify-content-center">
                  <div className="form-group map-select col-6">
                    <label htmlFor="country-select" className="text-secondary">SELECT COUNTRY TO MANAGE PERMITS</label>
                    <Select options={getCountriesObjectArray()} onChange={handleSelectCountry} />
                  </div>
                </div>
              )
            }
            {
             selectedRegion &&
              (
                <div className="d-flex w-100 justify-content-center">
                  <button className="btn btn-link mb-5" onClick={() => setSelectedRegion(null)}>Reset Selected Country</button>
                </div>
              )
            }
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                {
                  selectedRegion && requirements &&
                  (
                    <Fragment>
                    {
                      requirements.length > 0
                        ?
                        <Fragment>
                        {
                          requirements.map((requirement, index) => {
                            return (
                              <div className="card mb-5" key={index}>
                                <h5 className="card-header">{`${index + 1} - ${requirement.requirementRevisions[0].name}`} 
                                <BlueSwitch checked={!requirement.isBlacklisted} onClick={() => toggleBlacklisting(requirement)}/></h5>
                                {!requirement.isBlacklisted &&
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>Original Question</label>
                                          <p className="pl-0">
                                            {requirement.requirementRevisions[0].question}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label htmlFor={`customName${index}`}>Custom Question</label>
                                          <textarea
                                            id={`customName${index}`}
                                            className="form-control"
                                            type="text"
                                            value={inputs[`${requirement.id}`].question}
                                            onChange={(e) => handleFormChange(e, requirement.id, 'question') } 
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label>Original Description</label>
                                          <p className="pl-0">
                                            {requirement.requirementRevisions[0].description}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group">
                                          <label htmlFor={`customDesc${index}`}>Custom Description</label>
                                          <TextareaAutosize
                                            className="form-control text-area-sizing"
                                            type="text"
                                            id={`customDesc${index}`}
                                            value={inputs[`${requirement.id}`].description}
                                            onChange={(e) => handleFormChange(e, requirement.id, 'description') }
                                            onKeyPress={e => {
                                              if(e.key === 'Enter')
                                                  e.preventDefault()
                                            }} // Don't allow enter key it's not stored in the DB.
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row no-gutters">
                                      <button
                                        className="btn btn-danger btn-sm mr-3"
                                        disabled={isDecommissionDisabled(requirement)}
                                        // onClick={() => decommissionRequirement(requirement)}
                                        onClick={() => { setRequirementToDecommission(requirement); toggleDecommissionModal(); }}
                                      >
                                        Decommission
                                      </button>
                                      <button
                                        className="btn btn-primary btn-sm"
                                        disabled={isSaveDisabled(requirement)}
                                        onClick={() => saveRequirement(requirement)}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                }
                              </div>
                            );
                          })
                        }
                        </Fragment>
                        :
                        <p>There are no requirements configured for this country.</p>
                    }
                    </Fragment>
                  )
                }
                </div>
              </div>
            </div>
 
          </div>

          <Modal isOpen={isModalOpen} toggle={toggleDecommissionModal}>
            <ModalBody>
              <strong>Are you sure you want to decommission these custom requirement values? </strong><br />
              <small>Please note, this will not affect the global requirement settings.</small>
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-secondary" onClick={() => {setRequirementToDecommission(null); toggleDecommissionModal()}}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={decommissionRequirement}>Remove</button>
            </ModalFooter>
          </Modal>

        </Fragment>
      )
    }
    </Fragment>
  );
};

export default ManageCompanyRequirements;
