import React, { Fragment, useEffect, useState } from "react";
import { api } from "../../api";
import Header from "../../general/navigation/Header";
import Loading from "../../general/components/Loading.js";
import VisaGovernmentProcessingTimeRow from "./VisaGovernmentProcessingTimeRow";
import Select from "react-select";

const VisaGovernmentProcessingTime = () => {
  // Retrieve the country id
  const countryId = localStorage.getItem("countryId");
  // Retrieve the region id
  const regionId = localStorage.getItem("regionId");

  // Setup state variables
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [filteredCountry, setFilteredCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [visas, setVisas] = useState([]);
  const [governmentProcessingData, setGovernmentProcessingData] = useState([]);

  useEffect(() => {
    if (!regionId || !countryId) {
      window.location.replace("/#/");
    } else {
      setupPageData();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetching data
  const setupPageData = async () => {
    await fetchCountryData();
    await fetchVisas();
  };

  const fetchCountryData = async () => {
    try {
      const response = await api.get(`/countries`);
      const countryRes = response.data.filter(
        (item) => item.id.toString() === countryId.toString()
      )[0];
      const countriesRes = response.data.filter(
        (item) => item.id.toString() !== countryId.toString()
      );
      setCountry(countryRes);
      setCountries(countriesRes);
    } catch (error) {}
  };

  const fetchVisas = async () => {
    try {
      const visasResponse = await api.get(`/visas`);
      setVisas(visasResponse.data);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetchVisas", error);
    }
  };

  const handleRegionSelect = (event) => {
    if (event.value === "All") {
      setFilteredCountry(null);
    } else {
      setFilteredCountry(
        countries.filter((country) => country.id === event.value.id)[0]
      );
    }
  };

  const processingTimesCountryList = () => {
    let countryList = [{ label: "All", value: "All" }];
    countries.forEach((item) => {
      countryList = [...countryList, { label: item.name, value: item }];
    });
    return countryList;
  };

  const saveMultiple = async (visaObject) => {
  try {
    await api.patch('/visas', visaObject)
    await api.post('/visas/all', visaObject)

  } catch (error) {
    console.log(error)
  }
    setGovernmentProcessingData([])
    setupPageData()
  };

  // Delete functionality
  // const deleteMultiple = (entries) => {
  //   entries.map(async (data) => {
  //     try {
  //       if(data.id){
  //         await api.delete(`/visas/${data.id}`)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   setupPageData()
  // }

  return (
    <Fragment>
      <Header
        showClientNavigation={false}
        showImmigrationNavigation={true}
        displayName={country ? country.name : ""}
        link={"/select-country"}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <div className="container">
            <h3 className="gw__section-headings">
              Government Processing Times
            </h3>
          </div>
          <div className="container d-flex justify-content-center">
            <div className="form-group mt-2 mb-4 col-6">
              <label htmlFor="country-select" className="text-secondary">
                SELECT COUNTRY
              </label>
              <Select
                options={processingTimesCountryList()}
                onChange={(event) => handleRegionSelect(event)}
              />
            </div>
          </div>
          <div className="container d-flex justify-content-center">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Source Country</th>
                  <th scope="col">Processing Time (Weeks)</th>
                  <th scope="col">Premium Processing Time (Weeks)</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filteredCountry ? (
                  <VisaGovernmentProcessingTimeRow
                    country={filteredCountry}
                    destinationCountry={country}
                    setupPageData={setupPageData}
                    visasRes={visas}
                    setGovernmentProcessingData={setGovernmentProcessingData}
                  />
                ) : (
                  countries.map((sourceCountry, index) => {
                    return (
                      <VisaGovernmentProcessingTimeRow
                        key={index}
                        country={sourceCountry}
                        destinationCountry={country}
                        setupPageData={setupPageData}
                        visasRes={visas}
                        setGovernmentProcessingData={setGovernmentProcessingData}
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
          <div>
            <button
            type="button"
            onClick={() =>
              saveMultiple(governmentProcessingData)
            }
            className="btn btn-primary"
            style={{position:"fixed", top:'200px', left:'0%'}}
            >Save All</button>
             {/* <button
            type="button"
            onClick={() =>
              deleteMultiple(visas)
            }
            className="btn btn-danger"
            style={{position:"fixed", top:'270px', left:'0%'}}
            >Delete All</button> */}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default VisaGovernmentProcessingTime;
