import React, { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { api } from '../../api';
import Header from '../../general/navigation/Header';
import Loading from '../components/Loading';

const ManageFieldsOfStudy = () => {
  const [isDecommissionFieldOfStudyModalOpen, setIsDecommissionFieldOfStudyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fieldsOfStudy, setFieldsOfStudy] = useState([]);
  const [fieldOfStudyToDelete, setFieldOfStudyToDelete] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    fetchFieldsOfStudy();
  }, []);

  const fetchFieldsOfStudy = async () => {
    const fieldsOfStudyResponse = await api.get(`/fields-of-study`);
    setFieldsOfStudy(fieldsOfStudyResponse.data);
    setIsLoading(false);
  };

  const handleFieldOfStudyDecommission = async () => {
    setIsLoading(true);
    await api.delete(`/fields-of-study/${fieldOfStudyToDelete}`);
    toggleDecommissionFieldOfStudyModal();
    setFieldOfStudyToDelete(null);
    fetchFieldsOfStudy();
  };

  const toggleDecommissionFieldOfStudyModal = () => {
    setIsDecommissionFieldOfStudyModalOpen(!isDecommissionFieldOfStudyModalOpen);
  };

  return (
    redirectTo
    ? <Navigate to={redirectTo} />
    : isLoading
      ? <Loading />
      : <Fragment>
          <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
          <div className="container">
            <div className="row d-flex justify-content-between mt-5">
              <h4>Fields of Study</h4>
              <div className="">
                <button className="btn btn-primary" onClick={() => setRedirectTo('/manage/fields-of-study/create')}>
                  + Add Field of Study
                </button>
              </div>
            </div>

            <div className="row">
              <table className="table table-hover mt-5">
                <thead>
                  <tr>
                    <th className="text-secondary">Name</th>
                    <th className="text-secondary">Description</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    fieldsOfStudy.map((fieldOfStudy, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-secondary">{fieldOfStudy.name}</td>
                          <td className="text-secondary">{fieldOfStudy.description}</td>
                          <td className="text-secondary text-right">
                            <button className="btn btn-primary mr-3" onClick={() => setRedirectTo(`/manage/fields-of-study/${fieldOfStudy.id}/edit`)}>Edit</button>
                            <button className="btn btn-danger" onClick={() => { setFieldOfStudyToDelete(fieldOfStudy.id); toggleDecommissionFieldOfStudyModal(); }}>Decommission</button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

          <Modal isOpen={isDecommissionFieldOfStudyModalOpen} toggle={toggleDecommissionFieldOfStudyModal}>
            <ModalBody>
              <p>Are you sure you want to decommission this field of study? This is an irreversible act.</p>
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-secondary" onClick={toggleDecommissionFieldOfStudyModal}>Close</button>
              <button type="button" className="btn btn-danger" onClick={() => handleFieldOfStudyDecommission(fieldOfStudyToDelete)}>Decommission</button>
            </ModalFooter>
          </Modal>
        </Fragment>
  )
};

export default ManageFieldsOfStudy;
