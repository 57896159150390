import React, { useState, useEffect, useContext } from 'react';
import { api } from '../../api';
import Header from '../../general/navigation/Header';
import { CompanyContext } from '../../context/CompanyContext';

const ManageJobPositions = () => {
  const companyId = localStorage.getItem('companyId');
  const [companyState] = useContext(CompanyContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyPositions, setCompanyPositions] = useState([]);

  useEffect(() => {
    if (!companyState?.selectedCompany || !companyId) {
      window.location.replace('/#/');
    } else {
        setSelectedCompany(companyState.selectedCompany);
      api.get(`/companies/${companyId}/job-positions`)
        .then(response => {
          setCompanyPositions(response.data);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const addJobPosition = () => {
    setCompanyPositions(prevPositions => [
      ...prevPositions, 
      { id: null, jobFamily: '' }
    ]);
  };

  const onChangePosition = (event, index) => {
    const { name, value } = event.target;
    setCompanyPositions(prevPositions => {
      const updatedPositions = [...prevPositions];
      updatedPositions[index][name] = value;
      return updatedPositions;
    });
  };

  const removePosition = (index) => {
    setCompanyPositions(prevPositions => {
      const updatedPositions = [...prevPositions];
      updatedPositions.splice(index, 1);
      return updatedPositions;
    });
  };

  const savePositions = () => {
    if (!checkJobPositionsFilled()) {
      alert('Please fill job positions and ensure there are no duplicates before submission.');
    } else {
      api.patch(`/companies/${selectedCompany.id}/job-positions`, companyPositions)
        .then(() => {
          alert('Positions saved.');
        });
    }
  };

  const checkJobPositionsFilled = () => {
    let positionsValid = true;
    for (const position of companyPositions) {
      let duplicatePosition = companyPositions.filter(companyPosition => (companyPosition.jobFamily.trim() === position.jobFamily.trim()));
      if (duplicatePosition.length > 1 || !position.jobFamily) {
        positionsValid = false;
        break;
      }
    }
    return positionsValid;
  };

  return (
    <React.Fragment>
      {selectedCompany && (
        <React.Fragment>
          <Header showClientNavigation={true} showImmigrationNavigation={false} displayName={selectedCompany.name} link="/select-company" />
          <h3 className="container text-secondary text-bold pb-4">Job Positions</h3>
          <div className="container manage-countries-container d-flex justify-content-center align-items-center">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-secondary w-25" scope="col">Job Position</th>
                  <th scope="col"></th>
                  <th className="text-secondary" scope="col">Mapped Job Codes</th>
                </tr>
              </thead>
              <tbody>
                {companyPositions.map((position, index) => (
                  <tr key={index}>
                    <td className="text-secondary">
                      <input 
                        onChange={(event) => onChangePosition(event, index)} 
                        name="jobFamily" 
                        value={position.jobFamily} 
                        type="text" 
                        className="form-control"
                      />
                    </td>
                    <td>
                      <button 
                        type="button" 
                        onClick={() => removePosition(index)} 
                        className="btn btn-danger">X</button>
                    </td>
                    <td>
                      <select disabled multiple className="form-control listed-job-codes">
                        {position.jobCodes && position.jobCodes.map((jobCode, jobCodeIndex) => (
                          <option key={`${position.id}${jobCode.name}${jobCodeIndex}`}>{jobCode.name}</option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button type="button" onClick={addJobPosition} className="btn btn-secondary my-3">Add New Job Position</button>
            <button type="button" className="btn btn-primary" onClick={savePositions}>Save Changes</button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ManageJobPositions;
